@font-face {
  font-family: "Uthmani-Hafs";
  src: url("./assets/fonts/Uthmani-Hafs.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "LPMQ";
  src: url("./assets/fonts/lpmq.eot");
  src: url("./assets/fonts/lpmq.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/lpmq.ttf") format("truetype");
  font-weight: 400;
  font-weight: normal;
  font-display: swap;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}